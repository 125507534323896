import React from "react";

import SaltaLogo from "assets/img/brand/salta/justicia-logo-salta.png";
import SaltaLogoLight from "assets/img/brand/salta/justicia-logo-salta-light.png";
import SaltaFav from "assets/img/brand/salta/salta_iso.png";
import DefaultSalta from "assets/img/brand/salta/defaultSalta.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'salta';

export const sources = {
    salta: {
        url: document.location.origin,
        logo: SaltaLogo,
        logolight: SaltaLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Judiciales Salta',
        navbar_class: 'navbar-salta',
        headroom_class: 'headroom-salta',
        favicon: SaltaFav,
        theme_color: '#ae553b',
        theme_name: 'salta',
        defaultImg: DefaultSalta,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6LdeXeApAAAAAPsfbIWLnPgfyAX5y3z2ezShFAXu',
        gtm_ID: '',
        contact_data: {
            title: <><h5>Secretaría de subastas</h5>
                <h5>Poder Judicial de la Provincia de Salta</h5>
                </>,
            lines: [
                <p>
                    <b>Dirección</b>
                </p>,
                <p>
                    <b>Provincia</b>
                </p>,
                <p>
                    <b>Teléfonos:</b>
                </p>,
                <p>&emsp; completar (directo)</p>,
                <p>&emsp; completar (interno xxx)</p>,
                <p>
                    <b>email:</b> completar
                </p>,
            ],
        },
        support_email: 'soportesalta@esubasta.ar'
    },
};